<template>
  <v-card>
    <v-toolbar height="45" flat color="primary" dark>
      <v-toolbar-title class="white--text"
        ><v-icon class="white--text" left>{{ icons.mdiCarInfo }}</v-icon> Edit Tire</v-toolbar-title
      >
    </v-toolbar>
    <v-tabs>
      <v-tab>
        <v-icon left>
          {{ icons.mdiPencil }}
        </v-icon>
        Edit Tire
      </v-tab>
      <v-tab>
        <v-icon left>{{ icons.mdiCardAccountDetails }}</v-icon>
        Pricing
      </v-tab>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-form class="multi-col-validation">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-layout>
                    <v-autocomplete
                      v-model="inventory.brand_id"
                      :items="brands"
                      item-value="brand_id"
                      item-text="name"
                      outlined
                      color="secondary"
                      dense
                      chips
                      deletable-chips
                      small-chips
                      label="Brands"
                      single-line
                    ></v-autocomplete>
                    <v-btn @click.prevent="addnewBrandModal" class="mx-1" tile color="primary">
                      <v-icon left>{{ icons.mdiPlus }}</v-icon>
                      Add Brand
                    </v-btn>
                  </v-layout>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="inventory.model" color="secondary" label="Model" dense outlined></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="inventory.inv_category_id"
                    :items="inventoryCategories"
                    item-value="inv_category_id"
                    item-text="name"
                    outlined
                    color="secondary"
                    dense
                    chips
                    deletable-chips
                    small-chips
                    label="Inventory Category"
                    :prepend-inner-icon="icons.mdiChartBox"
                    single-line
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="inventory.vendor_id"
                    :items="vendors"
                    item-value="vendor_id"
                    item-text="vendor_name"
                    outlined
                    color="secondary"
                    dense
                    chips
                    deletable-chips
                    small-chips
                    label="Vendor"
                    :prepend-inner-icon="icons.mdiAccountMultipleOutline"
                    single-line
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-radio-group v-model="inventory.seasonality" class="mt-n1" row>
                    <h3 class="mr-5">Seasonality</h3>
                    <v-radio label="Summer" value="Summer"></v-radio>
                    <v-radio label="Winter" value="Winter"></v-radio>
                    <v-radio label="All seasons" value="All"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="inventory.sizes" color="secondary" label="Sizes" dense outlined></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="inventory.part_number"
                    dense
                    label="Part Number"
                    outlined
                    color="secondary"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="inventory.bin_location"
                    dense
                    label="Bin Location"
                    outlined
                    color="secondary"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="inventory.quantity"
                    dense
                    label="Quantity"
                    outlined
                    color="secondary"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="inventory.qty_on_hand"
                    dense
                    label="Quantity on Hand"
                    outlined
                    color="secondary"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-form class="multi-col-validation">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    v-model="inventory.pricing_id"
                    :items="pricings"
                    item-value="pricing_id"
                    item-text="name"
                    outlined
                    color="secondary"
                    dense
                    chips
                    deletable-chips
                    small-chips
                    label="Pricing Matrix"
                    :prepend-inner-icon="icons.mdiAccountMultipleOutline"
                    single-line
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="inventory.cost"
                    color="secondary"
                    prefix="$"
                    label="Cost"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="inventory.retail_price"
                    dense
                    label="Retail Price"
                    outlined
                    color="secondary"
                    prefix="$"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-radio-group v-model="inventory.markup" class="mt-n1" row>
                    <h3 class="mr-5">Markup</h3>
                    <v-radio
                      v-for="option in options"
                      :key="option.id"
                      :label="option.text"
                      :value="option.value"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    :append-icon="icons.mdiPercentOutline"
                    v-model="inventory.markup"
                    dense
                    label="Markup %"
                    outlined
                    color="secondary"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-radio-group v-model="inventory.margin" class="mt-n1" row>
                    <h3 class="mr-5">Margin</h3>
                    <v-radio
                      v-for="option in options"
                      :key="option.id"
                      :label="option.text"
                      :value="option.value"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    :append-icon="icons.mdiPercentOutline"
                    v-model="inventory.margin"
                    dense
                    label="Margin %"
                    outlined
                    color="secondary"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-switch class="mt-n4" flat label="Should this be taxed?"></v-switch>
                  <v-switch class="mt-n3" flat label="Does this customer receive a discount?"></v-switch>
                  <v-switch class="mt-n3" flat label="Does this customer have a labor rate override?"></v-switch>
                  <v-switch class="mt-n3" flat label="Does this customer have a labor matrix override?"></v-switch>
                  <v-switch class="mt-n3" flat label="Does this customer have a pricing matrix override?"></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" rounded @click.prevent="updateTireInventory">
              <v-icon right light>{{ icons.mdiContentSave }}</v-icon>
              <span>Save</span>
            </v-btn>
            <v-btn color="secondary" rounded @click.prevent="$router.push({ name: 'inventory' })">
              <v-icon right light>{{ icons.mdiClose }}</v-icon>
              <span>Cancel</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs>

    <addnew-brand
      :dialog.sync="showNewBrandDailog"
      @open-dialog="showNewBrandDailog = true"
      @close-dialog="showNewBrandDailog = false"
    ></addnew-brand>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import AddNewBrand from '@/views/settings/brand/AddNewBrand.vue'
import {
  mdiAccountTie,
  mdiCardAccountDetails,
  mdiAccountSupervisor,
  mdiCar,
  mdiPlus,
  mdiInformation,
  mdiContentSave,
  mdiBell,
  mdiCalendarAccount,
  mdiClose,
  mdiArchiveArrowDown,
  mdiTools,
  mdiCarInfo,
  mdiChartBox,
  mdiAccountMultipleOutline,
  mdiPercentOutline,
  mdiPencil,
} from '@mdi/js'

export default {
  components: {
    'addnew-brand': AddNewBrand,
  },
  created: function () {
    this.getInventoryCategories()
    this.getVendors()
    this.getPricings()
    this.getBrands()
    this.getInventories()
  },
  data() {
    return {
      inventoryCategories: [],
      vendors: [],
      pricings: [],
      pricingItems: [],
      brands: [],
      inventory: {},
      options: [
        {
          id: 1,
          text: '15%',
          value: '15.00',
        },
        {
          id: 2,
          text: '25%',
          value: '25.00',
        },
        {
          id: 3,
          text: '35%',
          value: '35.00',
        },
        {
          id: 4,
          text: '50%',
          value: '50.00',
        },
      ],
      showNewBrandDailog: false,
      icons: {
        mdiAccountTie,
        mdiCardAccountDetails,
        mdiAccountSupervisor,
        mdiCar,
        mdiPlus,
        mdiInformation,
        mdiContentSave,
        mdiBell,
        mdiCalendarAccount,
        mdiClose,
        mdiArchiveArrowDown,
        mdiTools,
        mdiCarInfo,
        mdiChartBox,
        mdiAccountMultipleOutline,
        mdiPercentOutline,
        mdiPencil,
      },
    }
  },
  methods: {
    getInventories: function () {
      this.$store
        .dispatch('inventory/editInventoryItems', {
          inv_id: this.$route.params.id,
        })
        .then(response => {
          this.inventory = this.fetchInventoryItems
        })
        .catch(err => {
          console.log(err)
        })
    },

    getInventoryCategories: function () {
      this.$store
        .dispatch('inventoryCategory/fetchInventoryCategories')
        .then(response => {
          this.inventoryCategories = this.fetchInventoryCategories
        })
        .catch(err => {
          console.log(err)
        })
    },
    getBrands: function () {
      this.$store
        .dispatch('brand/fetchBrands')
        .then(response => {
          this.brands = this.fetchBrands
        })
        .catch(err => {
          console.log(err)
        })
    },

    getVendors: function () {
      this.$store
        .dispatch('vendor/fetchVendors')
        .then(response => {
          this.vendors = this.fetchVendors
        })
        .catch(err => {
          console.log(err)
        })
    },
    getPricings: function () {
      this.$store
        .dispatch('pricing/fetchPricings')
        .then(response => {
          this.pricings = this.fetchPricings
        })
        .catch(err => {
          console.log(err)
        })
    },
    updateTireInventory: function () {
      this.$store
        .dispatch(`inventory/updateInventoryItem`, {
          inv_id: this.$route.params.id,
          data: this.inventory,
        })
        .then(response => {
          const Toast = this.$swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          })

          Toast.fire({
            icon: 'success',
            title: 'Record updated successfully',
          })
          this.$router.push({ name: 'inventory' })
        })
        .catch(error => {
          console.log(error)
        })
    },

    addnewBrandModal: function () {
      this.showNewBrandDailog = true
    },
  },

  computed: {
    ...mapGetters({
      fetchInventoryCategories: 'inventoryCategory/fetchInventoryCategories',
      fetchVendors: 'vendor/fetchVendors',
      fetchPricings: 'pricing/fetchPricings',
      fetchPricingItems: 'inventory/fetchPricingItems',
      fetchInventoryItems: 'inventory/fetchInventoryItems',
      fetchBrands: 'brand/fetchBrands',
    }),
  },
}
</script>

<style>
</style>